.custom-placeholder-input::placeholder {
    color: black; /* Couleur spécifique au placeholder */
    font-size: 15px; /* Taille de police spécifique au placeholder */
    font-weight: 600;
  }
  
  .spinner {
    border: 3px solid rgba(0, 0, 0, 0.1); /* Bord clair */
    border-top: 3px solid #fd2121; /* Bord coloré */
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 0.5s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  